import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EmployeesService} from './services/employees.service';
import {employeeActions} from './actions';
import {catchError, delay, map, of, switchMap, tap, withLatestFrom} from 'rxjs';
import {Store} from '@ngrx/store';
import {IEmployeesDataState} from './types/employees-data-state';
import {
  selectTimeStateCurrentYear,
  selectTimeStateSystemTime,
} from '../time/selectors/time.selectors';
import {NavController} from '@ionic/angular/standalone';
import {EAppRoutes} from '../../enums/app-routes.enum';
import {menuActions} from 'src/app/features/menu/store/actions';
import {HttpErrorHandlerService} from '../../services/http-error-handler.service';
import {alertActions} from 'src/app/features/alert/store/actions';
import {EMessageType} from '../../enums/message-type.enum';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {selectEmployeesStateRedirectPath} from './reducer';

@Injectable()
export class EmployeesFeatureEffects {
  fetchEmployees$ = createEffect(
    (
      httpErrorHandler = inject(HttpErrorHandlerService),
      actions$ = inject(Actions),
      store = inject(Store),
      employeeService = inject(EmployeesService),
    ) =>
      actions$.pipe(
        ofType(employeeActions.fetchEmployees),
        withLatestFrom(store.select(selectTimeStateCurrentYear)),
        switchMap(([action, currentYear]) => {
          return employeeService.fetchEmployees(currentYear).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.setCurrentYear({
                  payload: currentYear,
                }),
              );
              return employeeActions.successFetchingEmployees({
                payload: response,
              });
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(employeeActions.failedFetchingEmployees());
            }),
          );
        }),
      ),
  );

  createCoreTime$ = createEffect(
    (
      actions$ = inject(Actions),
      employeeService = inject(EmployeesService),
      httpErrorHandler = inject(HttpErrorHandlerService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(employeeActions.changeCoreTime),
        withLatestFrom(store.select(selectTimeStateCurrentYear)),
        switchMap(([action, currentYear]) => {
          return employeeService
            .changeCoreTime(action.payload, currentYear)
            .pipe(
              map((response: IEmployeesDataState) => {
                store.dispatch(
                  employeeActions.successFetchingEmployees({
                    payload: response,
                  }),
                );
                return employeeActions.changeCoreTimeSuccess();
              }),
              catchError((error: any) => {
                httpErrorHandler.handleError(error);
                return of(employeeActions.changeCoreTimeFailed());
              }),
            );
        }),
      ),
  );

  createCoreTimeSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(employeeActions.changeCoreTimeSuccess),
        tap(() => {
          navCtrl.navigateRoot(EAppRoutes.STAFF_TIME_REGULATION);
        }),
        map(() => {
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.STAFF.BOOKING_TIME_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.STAFF.BOOKING_TIME_SUCCESS',
          });
        }),
      ),
  );

  setCurrentEmployee$ = createEffect(
    (actions$ = inject(Actions), store = inject(Store)) =>
      actions$.pipe(
        ofType(employeeActions.setCurrentEmployee),
        withLatestFrom(store.select(selectTimeStateSystemTime)),
        map(([action, systemTime]) => {
          return employeeActions.setCurrentYear({
            payload: systemTime.getFullYear(),
          });
        }),
      ),
  );

  setCurrentMonth$ = createEffect((actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(
        employeeActions.setMonthOverview,
        employeeActions.setEmployeeMonth,
      ),
      delay(30),
      map(() => {
        return employeeActions.stopLoading();
      }),
    ),
  );

  setAlias$ = createEffect(
    (
      navCtrl = inject(NavController),
      actions$ = inject(Actions),
      router = inject(Router),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(employeeActions.setAlias),
        map((action) => {
          store.dispatch(
            employeeActions.setRedirectPath({
              payload: router.url as EAppRoutes,
            }),
          );
          navCtrl.navigateRoot(action.payload.path);
          return menuActions.aliasView();
        }),
      ),
  );

  removeAlias$ = createEffect(
    (
      navCtrl = inject(NavController),
      actions$ = inject(Actions),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(employeeActions.removeAlias),
        withLatestFrom(store.select(selectEmployeesStateRedirectPath)),
        map(([action, redirectPath]) => {
          if (redirectPath) {
            navCtrl.navigateRoot(redirectPath);
            store.dispatch(employeeActions.removeRedirectPath());
          }
          return menuActions.reset();
        }),
      ),
  );

  constructor() {}
}
